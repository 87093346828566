
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      redirect: this.$route.query.redirect,
    };
  },
})
export default class Unauthorized extends Vue {}
